<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <b-overlay>
      <b-row>
        <b-col
          v-if="programs3[0].programs_startupassignmenttables_aggregate.aggregate.count==0 && !programs3[0].is_super"
        >
          <b-card>
            <h4 class="text-center">
              No Assignments Found For You
            </h4>
          </b-card>
        </b-col>
        <b-col
          v-if="programs3[0].is_super"
        >
          <b-card
            style="height: 100%"
          >
            <h5 class="mb-2">
              All Startups
            </h5>
            <b-row>
              <b-col
                v-for="pro in programs"
                :key="pro.id"
                :md="programs3[0].is_super?'6':'4'"
              >
                <div
                  class="pt-1 mb-2"
                  style="border: solid 1px #7367F0;border-radius: 0.428rem;"
                >
                  <h3 class="px-2">
                    {{ pro.programs_applicantstable.users_organizationtable.title }}
                  </h3>
                  <h6 class="px-2 text-secondary">
                    <a :href="pro.programs_applicantstable.users_organizationtable.url">{{ pro.programs_applicantstable.users_organizationtable.url }}</a>
                  </h6>
                  <button
                    class="btn btn-block btn-primary btn-cart mt-1"
                    @click="$router.replace(`/mentor-portal/events/enter/${$route.params.id}/${$route.params.pid}/${pro.id}`)"
                  >
                    Select
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          v-if="programs3[0].programs_startupassignmenttables_aggregate.aggregate.count>0"
        >
          <b-card
            style="height: 100%"
          >
            <h5 class="mb-2">
              Startups Assigned to You
            </h5>
            <b-row>
              <b-col
                v-for="pro2 in programs2"
                :key="pro2.id"
                :md="programs3[0].is_super?'6':'4'"
              >
                <div
                  class="pt-1 mb-2"
                  style="border: solid 1px #7367F0;border-radius: 0.428rem;"
                >
                  <h3 class="px-2">
                    {{ pro2.programs_applicantstable.users_organizationtable.title }}
                  </h3>
                  <h6 class="px-2 text-secondary">
                    <a :href="pro2.programs_applicantstable.users_organizationtable.url">{{ pro2.programs_applicantstable.users_organizationtable.url }}</a>
                  </h6>
                  <button
                    class="btn btn-block btn-primary btn-cart mt-1"
                    @click="$router.replace(`/mentor-portal/events/enter/${$route.params.id}/${$route.params.pid}/${pro2.id}`)"
                  >
                    Select
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <section class="grid-view">
      <b-card
        v-for="(application, i) in applications"
        :key="i"
        :img-src="require('@/assets/images/slider/06.jpg')"
        :title="application.title"
        class="ecommerce-card"
        img-alt="card img"
        img-top
        no-body
      >
        <b-card-body>
          <b-card-title>{{ application.title }}</b-card-title>
          <b-card-text>
            <br> Round Number : {{ application.round_number }}
            <br> Status : {{ application.status }}
          </b-card-text>
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            v-if="application.review_status==='disabled'"
            v-b-modal.Nodata
            class="btn btn-wishlist btn-light"
            variant="primary"
          >
            <span>Report</span>
          </b-link>
          <b-link
            v-else
            v-b-modal.selectStartup
            class="btn btn-wishlist btn-light"
            variant="primary"
          >
            <span>Report</span>
          </b-link>
          <b-link
            :to="{ name: 'mentor-event-open-question', params: { pid: $route.params.id, apid: application.id }}"
            class="btn btn-primary btn-cart"
          >
            <span>Submit / View</span>
          </b-link>
        </div>
      </b-card>
    </section>
    <b-modal
      id="selectStartup"
      ok-only
      ok-title="OK"
      size="lg"
      title="Report"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="No report available"
            label-size="m"
          >
            <b-form-input
              v-model="selectProgram"
              placeholder="Select Startup"
              required
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label=""
            label-size="sm"
          >
            <treeselect
              v-model="selectedOperation"
              :multiple="true"
              :normalizer="normalizer"
              :options="operations"
              placeholder="Select Phase, Subphase or Activities"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardText, BCardBody, BCardTitle, BOverlay, BLink, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/utils/auth'
import Treeselect from '@riophae/vue-treeselect'
// import store from '@/store'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BOverlay,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    Treeselect,
  },
  data() {
    return {
      selectStartup: Number.parseInt(this.$route.params.aid, 10),
      programs: [],
      programs2: [],
      programs3: [],
      applications: [],
      userOrgs: getUserData()
        .associatedOrgDetails
        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  apollo: {
    programs: {
      query() {
        return gql`
        {
          programs_startupparticipants(where: {programs_applicantstable: {program_id: {_eq: ${this.$route.params.id}}}}) {
              programs_applicantstable {
                users_organizationtable {
                  title
                  url
                }
              }
              id
            }
        }`
      },
      update(data) {
        return data.programs_startupparticipants
      },
    },
    programs2: {
      query() {
        return gql`
        {
          programs_startupparticipants(where: {is_active: {_eq: true}, programs_startupassignmenttables: {partner_id: {_eq: ${this.$route.params.pid}}, is_active: {_eq: true}}}) {
            id
            programs_applicantstable {
              users_organizationtable {
                title
                url
              }
            }
          }
        }`
      },
      update(data) {
        return data.programs_startupparticipants
      },
    },
    programs3: {
      query() {
        return gql`
        {
          programs_partnerstable(where: {id: {_eq: ${this.$route.params.pid}}}) {
            is_super
            programs_startupassignmenttables_aggregate {
              aggregate {
                count(columns: id)
              }
            }
          }
        }`
      },
      update(data) {
        return data.programs_partnerstable
      },
    },

  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
